import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classnames from 'classnames';
import { compact, get } from 'lodash';
import { graphql, PageProps } from 'gatsby';

import SEO from '../../components/SEO/SEO';
import ComponentRenderer from '../../components/ComponentRenderer/ComponentRenderer';
import { StrapiImageAndTextComponent } from '../../components/StrapiComponents/StrapiImageAndTextComponent';
import { StrapiFooterCtaComponent } from '../../components/StrapiComponents/StrapiFooterCta';
import Tag from '../../../src/components/Tag';
import { StrapiHeroMap } from '../../components/StrapiComponents/StrapiHeroMap';
import LayoutWrapper, { intlWrapperHOC, v4tov3HOC } from '../../../src/components/LayoutWrapper';
import { CitySchoolsPostPageQuery, Scalars } from '../../../graphql.schema';
import { getMapMarkersFromSchools } from '../../lib/helpers';
import { GlobalPageContext } from '../../types';
import translations from '../../translations/en.json';
import { v4tov3base } from '../../lib/mapV4toV3';

import * as styles from './index.module.scss';

type PageContextType = GlobalPageContext & {
  documentId: Scalars['ID'];
};

const SchoolsIndex: React.FC<PageProps<CitySchoolsPostPageQuery, PageContextType>> = ({ data, pageContext }) => {
  const { city } = data.strapi;
  const { websiteLocale: websiteLocaleV3 } = pageContext;
  const websiteLocale = v4tov3base({ id: websiteLocaleV3?.documentId, ...websiteLocaleV3 });
  const intl = useIntl();
  const heroMapMarkers = getMapMarkersFromSchools(compact(city?.schools));

  const uniqueTags = [
    ...new Set(
      compact(city?.schools)
        .map(({ certifications }) => compact(certifications).map(({ name }) => name))
        .flat(),
    ),
  ];

  if (!websiteLocale || !city?.name)
    return (
      <p>
        <FormattedMessage id="notFoundTextHeading" />
      </p>
    );
    const showFooterCta = city?.pageSettings?.showFooterCta ?? false;
    const footerCta = city?.pageSettings?.customFooterCta?.documentId
      ? city?.pageSettings?.customFooterCta
      : websiteLocale.footerCta;
  return (
    <LayoutWrapper {...pageContext}>
      <SEO
        title={city.seo?.metaTitle ?? city?.pageSettings?.title ?? intl.formatMessage(
          { id: 'seo.city-school.title', defaultMessage: translations['seo.city-school.title'] },
          {
            city: city?.name || '',
            siteTitle: pageContext.websiteLocale.name,
          },
        )}
        description={city.seo?.metaDescription ?? city?.pageSettings?.metaDescription ?? intl.formatMessage(
          { id: 'seo.city-school.description', defaultMessage: translations['seo.city-school.description'] },
          { city: city?.name || '' },
        )}
        image={city.seo?.metaImage?.url}
        avoidIndexing={city.pageSettings?.avoidIndexing ?? false}
        canonicalURL={city.seo?.canonicalURL}
        keywords={city.seo?.keywords}
        metaSocial={city.seo?.metaSocial}
        structuredData={city.seo?.structuredData}
        currentPage={pageContext}
      />
      <div className={classnames('container', styles.main)}>
        { !city.hide_map ? (
          <StrapiHeroMap
          mapTitle={city.title ?? city.name ?? ''}
          markers={heroMapMarkers}
          mapDefaultZoom={9}
        />
         ): (
          <div className={styles.cityheroComponent}>
          <h1 className={classnames(styles.mainTitle, 'title is-1 brand-a')}>{city.title ?? city.name}</h1>
          </div>
         )
      }
        
        <div className={styles.tagsWrapper}>
          <div className={styles.tags}>   
            {uniqueTags.map((tag, i) => (
              <Tag isClickable={false} isActive={false} key={`${tag}-${i}`} name={tag ?? ''} />
            ))}
          </div>
        </div>
        <div className={classnames('container')}>
          {city.components?.map((component, idx) =>
            component ? (
              <ComponentRenderer
                relatedList={{ schools: compact(city.schools) }}
                schoolSlugBase={pageContext?.pathPrefixes?.schools ?? ''}
                component={{...component, cityName: city.name}}
                key={`city-schools-component-${component.__typename}-${idx}`}
              />
            ) : null,
          )}
          {city.imageAndText && (
            <StrapiImageAndTextComponent hasRTL={websiteLocale?.hasRightToLeftLanguage} {...city.imageAndText} />
          )}
        </div>
      </div>
      {footerCta && showFooterCta && <StrapiFooterCtaComponent {...footerCta} />}
    </LayoutWrapper>
  );
};

export default intlWrapperHOC(v4tov3HOC(SchoolsIndex));

export const query = graphql`
query CitySchoolsPostPage($documentId: ID!, $locale: Strapi_I18NLocaleCode) {
  strapi {
    city(documentId: $documentId,locale: $locale) {
      data {
        documentId
        attributes {
          name
          title
          hide_map
          localizations {
            data {
              attributes {
                locale
              }
            }
          }
          components {
            ...PageComponents
          }
          imageAndText: image_and_text {
            ...ImageAndTextComponent
          }
          pageSettings: page_settings {
            ...PageSettings
          }
          seo {
            ...SEOComponent
          }
          schools(pagination: {limit: 100}) {
            data {
              documentId
              attributes {
                ...School
              }
            }
          }
        }
      }
    }
  }
}
`;
